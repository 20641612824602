<template>
  <div>
    <ConnectWallet />
    <div class="z-0 font-sans" v-if="getToken == 'false'">
      <div
        class="bg-cover h-screen w-screen bg-center flex items-center justify-center"
        style="
          background-image: url('https://cdn.discordapp.com/attachments/1080058777971212370/1121285657885683784/AI.png');
        "
      >
        <div class="text-center">
          <span class="text-5xl font-bold sm:text-8xl text-white">
            AI in agency industry
          </span>
        </div>
      </div>

      <div
        class="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-10 pt-10 p-2"
      >
        <div class="text-center">
          <hr class="h-px my-4 border-blue-700 border" />

          <a
            class="text-xl font-medium xl:text-5xl hover:-translate-y-1 cursor-pointer text-shadow-white text-purple-900"
          >
            AI in agency industry
          </a>
          <hr class="h-px my-4 border-blue-700 border" />
        </div>

        <h3 class="text-xl font-medium xl:text-2xl text-purple-900">
          AI (Artificial Intelligence) is increasingly being utilized in the
          agency industry to streamline operations, improve efficiency, and
          enhance decision-making processes. Here are a few key areas where AI
          is making an impact:
        </h3>

        <div class="flex justify-center">
          <ul class="list-disc text-lg xl:text-xl mt-5">
            <li class="mt-2">
              Data Analysis and Insights: Agencies deal with vast amounts of
              data from various sources. AI can help in automating data analysis
              tasks, extracting valuable insights, and identifying patterns or
              trends. Machine learning algorithms can process and analyze data
              more quickly and accurately than humans, enabling agencies to make
              data-driven decisions and optimize their strategies.
            </li>
            <li class="mt-4">
              Personalization and Targeting: AI-powered algorithms can analyze
              consumer data, behavior, and preferences to deliver personalized
              experiences and targeted advertising campaigns. By leveraging AI,
              agencies can segment audiences, create personalized content, and
              optimize messaging to improve customer engagement and conversion
              rates.
            </li>
            <li class="mt-4">
              Chatbots and Virtual Assistants: AI-driven chatbots and virtual
              assistants are becoming prevalent in the agency industry. These
              intelligent systems can handle customer inquiries, provide
              support, and offer recommendations. Chatbots can streamline
              customer service processes, provide 24/7 support, and free up
              human agents to focus on more complex tasks. outcomes.
            </li>
            <li class="mt-4">
              Content Creation and Curation: AI can assist agencies in content
              creation and curation. Natural Language Processing (NLP)
              algorithms can generate written content, such as articles or
              product descriptions, based on given parameters. AI can also
              curate and recommend relevant content to users based on their
              preferences and behavior, enhancing content discovery and
              engagement.
            </li>

            <li class="mt-4">
              Predictive Analytics and Optimization: AI algorithms can analyze
              historical data and predict future outcomes, allowing agencies to
              optimize campaigns, allocate resources effectively, and make
              informed decisions. By leveraging predictive analytics, agencies
              can optimize ad placements, predict customer behavior, and enhance
              overall campaign performance.
            </li>

            <li class="mt-4">
              Media Buying and Optimization: AI-powered tools can automate media
              buying processes, optimizing ad placements, and budgets based on
              real-time performance data. AI algorithms can continuously monitor
              and adjust campaigns, making data-driven decisions to maximize
              return on investment (ROI) and ad effectiveness.
            </li>
          </ul>
        </div>

        <h3 class="text-lg font-bold xl:text-lg mt-5">
          While AI brings significant benefits to the agency industry, it's
          important to note that human expertise and creativity remain crucial.
          AI tools are best utilized when combined with human insights,
          strategic thinking, and creative ideation, allowing agencies to
          leverage the power of AI while maintaining a human touch.
        </h3>
      </div>

      <News />
      <Footer />
    </div>
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet.vue";
import News from "@/components/News.vue";
import Footer from "@/components/Footer.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      news: [
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1121285657420112053/AI_Replace_Human.png",
          title: "Will Artificial Intelligence (AI) replace humans ?",
          list: "1",
        },
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1121285657885683784/AI.png",
          title: "AI in agency industry",
          list: "2",
        },
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1121285658401579018/Comparison.png",
          title: "Comparison between virtual influencer and human influencer ?",
          list: "3",
        },
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1121285658795847692/What_is_virtualKOL.png",
          title: "What is VirtualKOL ?",
          list: "4",
        },
      ],
    };
  },
  components: {
    ConnectWallet,
    News,
    Footer,
  },
  computed: mapGetters(["getToken"]),

  methods: {},
  created() {},
};
</script>
